import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {Reservee, Details, Status} from '@wix/ambassador-table-reservations-v1-reservation/types'
import {ReservationLocation} from '@wix/ambassador-table-reservations-v1-reservation-location/types'

import {reservationsService} from '../../../services/reservationsService'
import {RequestStatus} from '../../../utils/wrapRequest'
import {goToReservationConfirmation} from '../../../utils/navigation'
import {getLogger} from '../../../utils/getLogger'
import {checkoutService} from '../../../services/checkoutService'
import {EXPERIMENTS} from '../../../utils/constants'

export const createReservation = (flowAPI: ControllerFlowAPI) => {
  return async (details: Details, reservee: Reservee, reservationLocation: ReservationLocation) => {
    const setProps = flowAPI.controllerConfig.setProps
    const logger = getLogger(flowAPI.bi!)
    const isPaymentRuleFlowEnabled = flowAPI.experiments.enabled(
      EXPERIMENTS.isPaymentRuleFlowEnabled,
    )

    try {
      setProps({submitReservationStatus: RequestStatus.LOADING})
      const finishedReservation = await reservationsService.createReservation(flowAPI, {
        details,
        reservee,
      })

      // this is the only place where can get just created reservation
      if (finishedReservation?.id) {
        logger.reservationCreated({
          isPreview: false,
          reservation: finishedReservation,
          reservee,
          reservationLocation,
        })

        if (!isPaymentRuleFlowEnabled) {
          return goToReservationConfirmation(flowAPI, finishedReservation.id)
        }

        const checkoutResponse = await checkoutService.createCheckout(
          flowAPI,
          finishedReservation.id,
          reservee,
        )

        if (!checkoutResponse) {
          throw new Error('Checkout is undefined')
        }

        let order

        try {
          order = await checkoutService.createOrderFromCheckout(
            flowAPI,
            checkoutResponse.checkout!.id!,
          )
        } catch (error) {
          if (
            finishedReservation.status === Status.RESERVED ||
            finishedReservation.status === Status.REQUESTED
          ) {
            console.error('Error creating order from checkout', error)
          } else {
            throw error
          }
        }

        await goToReservationConfirmation(flowAPI, finishedReservation.id, false, order?.orderId)
      } else {
        throw new Error('No reservation')
      }
    } catch (err: any) {
      setProps({
        submitReservationStatus: RequestStatus.FAILED,
        apiRequestErrorDetails: err,
      })
    }
  }
}
