export interface ApiError<T extends string> {
  applicationCode?: T
  message?: string
}

interface WixHttpError {
  isWixHttpError: boolean
  requestId: string
  response: {
    data?: {
      message?: string
      details: {
        applicationError: {
          code?: string
        }
      }
    }
    status: number
  }
}

export const isWixHttpError = (err: any): err is WixHttpError => err?.isWixHttpError
