import {CreateControllerFn} from '@wix/yoshi-flow-editor'
import {createEventHandler} from '@wix/tpa-settings'

import {isTemplateView} from '../../utils/flowAPI'
import {SettingsEvents, SettingsEventsValues} from '../../utils/constants'

import {initReservationDetailsStorage, mockReservationDetailsStorage} from './storage'

const createController: CreateControllerFn = async ({flowAPI, controllerConfig}) => {
  const publicData = controllerConfig.config.publicData.COMPONENT || {}
  const handler = createEventHandler<SettingsEventsValues>(publicData)

  return {
    async pageReady() {
      const isEditor = flowAPI.environment.isEditor
      const isPreview = flowAPI.environment.isPreview
      const isTemplate = isTemplateView(flowAPI)

      handler.on(SettingsEvents.APPROVAL_TEXT_SETTINGS, (value) => {
        flowAPI.controllerConfig.setProps({approvalTextEditorState: value})
      })

      if (isEditor || isPreview || isTemplate) {
        const mockedStorage = await mockReservationDetailsStorage(flowAPI)
        flowAPI.controllerConfig.setProps(mockedStorage)
      } else {
        const storage = initReservationDetailsStorage(flowAPI)
        flowAPI.controllerConfig.setProps(storage)

        if (storage.isReservationIdQueryParamValid) {
          await Promise.all([
            storage.getReservation(storage.reservationIdQueryParam!),
            storage.getReservationLocations(),
          ])
        } else if (storage.isReservationDataQueryParamsValid) {
          await storage.getReservationLocations()
        } else {
          storage.goToNewReservation()
        }
      }
    },
    updateConfig(_, config) {
      handler.notify(config.publicData.COMPONENT || {})
    },
  }
}

export default createController
